@charset "utf-8";
/* ================================================================================
値の定義
================================================================================ */

// フォントサイズ
$mainRemFontSize: 62.5%; // =10pxに相当
// 基本フォントサイズ(SP)
$mainFontSpSize: 100%;
// 基本横幅
$basicWidth: 1020px;

// 基本カラー
$basicColor: #353536;
$basicWhiteColor: #fff;
$keyColor: #CBA671;
$borderColor: #999;
$thinBorderColor: #ccc;
$bgColor: #E2E0DC;
$mainColor: #98c043;

// 基本フォント
@import url('https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300;400;500&display=swap');
$base-font:"Kiwi Maru",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴシック",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Verdana,
sans-serif;

$yakuhan:YakuHanJP_Noto,
"Noto Sans JP",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴシック",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Verdana,
sans-serif;

// ブレークポイント定義
$breakpoints: ("md": "screen and (max-width : 767px)",
  "tab": "screen and (min-width : 768px) and (max-width : 1024px)",
  "desk": "screen and (min-width : 1025px) and (max-width : 1440px)",
  "pc": "screen and (min-width : 768px)",
  "tabDesk": "screen and (min-width : 768px) and (max-width : 1440px)",
  "fixed": "screen and (min-width : 1441px) and (max-width : 1920px)",
  "max": "screen and (min-width : 1921px)",
) !default;

$properties: ('p': 'padding',
  'pt': 'padding-top',
  'pl': 'padding-left',
  'pb': 'padding-bottom',
  'pr': 'padding-right',
  'm': 'margin',
  'mt': 'margin-top',
  'ml': 'margin-left',
  'mb': 'margin-bottom',
  'mr': 'margin-right',
  'w': 'width',
  'mw': 'min-width',
  'h': 'height',
  'mh': 'min-height',
  't': 'top',
  'l': 'left',
  'b': 'bottom',
  'r': 'right',
  'br': 'border-radius',
  'btlr': 'border-top-left-radius',
  'btrr' : 'border-top-right-radius',
  'bblr' : 'border-bottom-left-radius',
  'bbrr' : 'border-bottom-right-radius',
  'btw': 'border-top-width',
  'bbw' : 'border-bottom-width',
  'brw' : 'border-right-width',
  'blw' : 'border-left-width',
) !default;

/* ================================================================================
importファイル
================================================================================ */

// @mixin定義
@import "foundation/mixin";

// @extend定義
@import "foundation/extend";

// リセット
@import "foundation/reset";

// 印刷
@import "foundation/print";

// 基本 
@import "layout/bace";

// ヘッダー
@import "layout/header";

// フッター
@import "layout/footer";

// コンポーネント（共通パーツ）
@import "component/title";
@import "component/animation";
@import "component/button";
@import "component/common";

/************************************
トップページ
***********************************/

// トップページ
@import "page/index";

// ポリシーページ
@import "page/policy";

// ショップ情報ページ
@import "page/shop";

// ショップ情報ページ
@import "page/contact";
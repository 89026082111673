@charset "utf-8";
/* ==============================================
ポリシーページ
============================================== */

#policyTop{
	.policyContent{
		@include props(pt,160);
		@include props(pb,200);
		width: 100%;
		margin: 0 auto;
	
		@include mq(md){
		  @include props(pt,120,750);
		  @include props(pb,200,750);
		  @include props(pl,40,750);
		  @include props(pr,40,750);
		}
		p{
			@include fz_vw(16);
			@include mq(md){
				@include fz_vw(28);
			}
		}
	}
}
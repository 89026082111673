@charset "utf-8";

/* =====================================
ヘッダー
===================================== */

#header {
  @include props(pt, 30);
  @include props(pb, 30);
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  background: $bgColor;
  transition: box-shadow .3s;

  &.changeColor{
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,.1);
  }

  @include mq(md){
    @include props(pt,25,750);
    @include props(pb,25,750);
  }
  .headerContents{
    position: relative;
    align-items: center;
    transition: .5s;
    @include props(pr, 40);
    @include props(pl, 40);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .headerLogo{
      width: 18.38%;

      @include mq(md) {
        @include props(w, 327, 750);
      }
    }


    .navBtn {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      z-index: 999;
      @include props(pr, 40, 750);
      @include props(w, 100, 750);
      @include props(h, 28, 750);
      cursor: pointer;
  
      span {
        display: inline-block;
        position: absolute;
        @include props(r, 40, 750);
        @include props(w, 50, 750);
        height: 1px;
        background-color: #4d4d4d;
        transition: transform .5s ease-in-out , opacity .5s ease-in-out;
        &:nth-of-type(1) {
          @include props(w, 50, 750);
          @include props(t, 28, 750);
        }
        &:nth-of-type(2) {
          top: 49%;
          @include props(w, 50, 750);
        }
        &:nth-of-type(3) {
          @include props(b, 28, 750);
        }
      }
      &.isOpen {
        span {
          &:nth-of-type(1),
          &:nth-of-type(3) {
            top: 50%;
            background-color: #222;
          }
          &:nth-of-type(1) {
            transform: rotate(-150deg);
            z-index: 1;
          }
          &:nth-of-type(2) {
            opacity: 0;
          }
          &:nth-of-type(3) {
            transform: rotate(150deg);
          }
        }
      }
    }

    .navArea{
      @include mq(md) {
        position: fixed;
        top: 0;
        right: 0;
        max-width: 69.3%;
        width: 100%;
        height: 100%;
        z-index: 998;visibility: hidden;
        -webkit-overflow-scrolling: touch;
        transition: visibility .7s .8s;
  
        &.isShow {
          visibility: visible;
          transition: visibility .7s;
          &::after {
            transition: transform .7s;
            transform: translateX(0);
          }
          .content {
            transition: opacity .8s .8s, transform .7s .8s;
            opacity: 1;
            transform: translateY(0);
          }
        }
    
        &::after{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: -5px 0 20px 0 #0000000d;
          background: $bgColor;
          z-index: -1;
          transform: translateX(100%);
          transition: transform .7s .9s;
        }
        
        .content{
          overflow-y: scroll;
          width: 100%;
          height: 100%;
          opacity: 0;
          transform: translateY(20px);
          transition: opacity .8s, transform .7s;
          @include props(pt, 250, 750);
          @include props(pb, 130, 750);
          @include props(pr, 40, 750);
          @include props(pl, 53, 750);
        }
      }
    }

    .pcGlobalNav{
      transition: .5s;
      @include props(pl, 80);
  
      @include mq(md) {
        @include props(pl, 0);
      }
  
      ul{
        display: flex;
        align-items: center;
  
        @include mq(md) {
          display: block;
        }
  
        .pcGlobalNavList{
          &:not(:last-child){
            @include props(mr, 60);

            @include mq(tab){
              @include props(mr, 30);
            }
          }

          &:last-child{
              display: inline-block;
              text-align: center;
              @include props(w,280);
              border-radius: 100px;
              background: $keyColor;
              transition: all .3s;
              border: 1px solid $keyColor;

              @include mq(tab){
                @include props(w,200);
              }

              a{
                color: $basicWhiteColor;
                padding: 1.3em .5em;
              }
        
              &:hover{
                cursor: pointer;
                background: $basicWhiteColor;
                color: $keyColor;

                a{
                  color: $keyColor;
                }
              }
          }
  
          a{
            display: block;
            @include fz_vw(16);
            line-height: 1;
            letter-spacing: 0.9px;
            transition: opacity .3s;

            @include mq(pc){
              padding: .5em;

              &.current{
                border-bottom: 2px solid $keyColor
              }
            }
          }
  
          @include mq(md) {
            @include props(mb, 60, 750);
            &:not(:last-child){
              @include props(mr, 0);
            }

            &:last-child{
              @include props(mt, 30, 750);
              width: 100%;

              a{
                padding: 1em .5em;
              }
            }
    
            a{
              @include fz_vw(32);
              letter-spacing: 1px;
            }

          }
        }
      }
    }
  }
}
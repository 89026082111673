@charset "utf-8";
/* ==============================================
トップページ
============================================== */

#indexTop{
  overflow: hidden;

  .mainVisual{
    position: relative;
    @include props(mb,160);

    @include mq(md){
      @include props(mb,80,750);
    }

    .mainVisualImg{
      width: 100%;
      @include props(h,800);
      position: relative;
      opacity: 0;
      transition: opacity .6s;

      &.isShow{
        opacity: 1;
      }

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include mq(md){
        @include props(h,450,750);

        img{
          height: 100%;
          max-height: 100%;
        }
      }

      .mainVisualTit{
        opacity: 0;
        transition: opacity .6s;
        position: absolute;
        @include props(b,80);
        width: 100%;
        max-width: 1560px;
        padding: 0 20px;
        left: 50%;
        transform: translate(-50%,0);
        @include fz_vw(48);
        font-weight: 500;

        &.isShow{
          opacity: 1;
        }
  

        @include mq(md){
          @include props(b,40,750);
          @include props(pl,40,750);
          @include props(pr,40,750);
          @include fz_vw(34);
        }
      }
    }

    .mainVisualReadArea{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      @include props(mt,120);

      @include mq(md){
        @include props(mt,120,750);
      }

      .mainVisualReadTti{
        color: $keyColor;
        @include fz_vw(60);
        line-height: 1;
        width: 26.8%;

        @include mq(md){
          @include fz_vw(48);
          width: 100%;
        }
      }

      .mainVisualRead{
        width: 73.2%;
        @include fz_vw(18);
        line-height: 2;

        span{
          font-weight: 500;
        }

        @include mq(md){
          @include props(mt,40,750);
          @include fz_vw(32);
          width: 100%;
        }
      }
    }
  }

  .mainBottomImgList{
    ul{
      display: flex;
      flex-wrap: wrap;

      li{
        width: 32.8vw;

        &:not(:last-child){
          @include props(mr,60);
        }

        &:nth-child(n+3){
          @include props(mt,60);
        }

        &:nth-child(1){
          img{
            @include props(btrr,20);
            @include props(bblr,20);
          }
        }
        &:nth-child(2){
          img{
            @include props(btlr,20);
            @include props(bbrr,20);
          }
        }
        &:nth-child(3){
          margin-left: auto;
          img{
            @include props(btlr,20);
            @include props(bbrr,20);
          }
        }
        &:nth-child(4){
          img{
            @include props(btrr,20);
            @include props(bblr,20);
          }
        }

        img{
          width: 100%;
        }

        @include mq(md){
          width: 43.21vw;
          &:nth-child(1){
            @include props(mr,60,750);
            img{
              @include props(btrr,20,750);
              @include props(bblr,20,750);
            }
          }
          &:nth-child(2){
            @include props(mr,0,750);
            img{
              @include props(btlr,20,750);
              @include props(bbrr,20,750);
            }
          }
          &:nth-child(3){
            @include props(mr,60,750);
            img{
              @include props(btlr,20,750);
              @include props(bbrr,20,750);
            }
          }
          &:nth-child(4){
            @include props(mr,0,750);
            img{
              @include props(btrr,20,750);
              @include props(bblr,20,750);
            }
          }

          &:nth-child(n+3){
            @include props(mt,60,750);
          }
        }
      }
    }
  }

  .step{
    @include props(pt,200);

    @include mq(md){
      @include props(pt,160,750);
    }

    .stepList{
      display: flex;
      flex-wrap: wrap;
      counter-reset: number 0;
      
      li{
        width: 59.29%;
        background: #EFEFEF;
        border-top-left-radius:50px;
        @include props(bbrr,60);
        position: relative;
        &::before{
          counter-increment: number 1;
          content: "0"counter(number);
          position: absolute;
          top: 0;
          @include props(r,40);
          transform: translate(0,-50%);
          @include fz_vw(70);
          color: $keyColor;
          -webkit-text-stroke: 2px #EFEFEF;
        };

        &:not(:nth-child(1)){
          display: flex;
        }

        &:nth-child(1){
          a{
            display: flex;
            width: 100%;
          }
        }

        &:not(:last-child){
          @include props(mb,80);
        }

        &:nth-child(even){
          margin-left: auto;
        }

        @include mq(md){
          width: 100%;
          @include props(btlr,60,750);
          @include props(bbrr,60,750);

          &::before{
            @include props(r,30,750);
            @include fz_vw(64);
            -webkit-text-stroke: 1px #EFEFEF;
          }

          &:not(:last-child){
            @include props(mb,80,750);
          }
        }

        
        .imgBox{
          width: 50%;
          border-top-left-radius:50px;
          overflow: hidden;
          @include mq(md){
            @include props(btlr,60,750);
          }

          img{
            border-top-left-radius:50px;
            transition: transform 1.5s;
            object-fit: cover;

            @include mq(md){
              @include props(btlr,60,750);
            }
          }
        }

        @include mq(pc){
          &:hover{
            cursor: pointer;
            .imgBox{
              img{
                transform: scale(1.02);
              }
            }
          }
        }

        .txtBox{
          width: 50%;
          position: relative;
          @include props(pl,40);
          @include props(pr,40);
          display: flex;
          flex-direction: column;
          justify-content: center;

          h4{
            @include fz_vw(28);
            text-align: center;
          }
          p{
            position: absolute;
            @include fz_vw(18);
            @include props(b,40);
            @include props(r,40);

            &.linkIcon{
              &:after{
                content: "";
                border-right: 1px solid $basicColor;
                border-top: 1px solid $basicColor;
                display: inline-block;
                vertical-align: middle;
                width: .6em;
                height: .6em;
                margin-left: 0.6em;
                transform: rotate(45deg);
              }
            }
            &.modalIcon{
              &:after{
                content: "";
                background: url(/images/common/modal_icon.svg) center center no-repeat;
                background-size: contain;
                display: inline-block;
                vertical-align: middle;
                width: 1em;
                height: 1em;
                margin-left: 0.6em;
              }
            }
          }

          @include mq(tab){
            @include props(pl,30);
            @include props(pr,30);
            h4{
              @include fz_vw(24);
            }
          }

          @include mq(md){
            @include props(pl,20,750);
            @include props(pr,20,750);

            h4{
              @include fz_vw(32);
            }

            p{
              @include fz_vw(24);
              @include props(b,30,750);
              @include props(r,30,750);
            }
          }
        }
      }
    }
  }

  .priceAndCourses{
    @include props(pt,200);
    width: 100%;
    max-width: 1064px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;

    @include mq(md){
      @include props(pt,160,750);
    }

    .infoArea{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      .priceListWrap{
        width: 43.94%;
        @include mq(md){
          width: 100%;
        }

        .priceArea{
          &:not(:last-of-type){
            @include props(mb,30);

            @include mq(md){
              @include props(mb,60,750);
            }
          }

          .priceMainTit{
            @include fz_vw(22);
            padding-bottom: .5em;
            margin-bottom: .5em;
            border-bottom: 1px solid $basicColor;
            @include mq(md){
              @include fz_vw(32);
            }
          }

          .priceDate{
            .priceDateArea{
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;

              .priceDateTit{
                width: 44.44%;
                @include fz_vw(18);
                margin-bottom: .5em;

                @include mq(md){
                  @include fz_vw(28);
                }
              }

              .priceDateDesc{
                width: 47.77%;
                @include fz_vw(18);
                text-align: right;
                margin-bottom: .5em;

                @include mq(md){
                  @include fz_vw(28);
                }
              }
            }
          }
        }
      }

      .img{
        width: 50.29%;

        @include mq(md){
          width: 100%;
          @include props(mt,60,750);
        }

        img{
          @include props(btlr,60);
          @include props(bbrr,60);

          @include mq(md){
            @include props(btlr,60,750);
            @include props(bbrr,60,750);
          }
        }
      }
    }
  }

  .faq{
    @include props(pt,300);
    @include props(mb,200);
    width: 100%;
    max-width: 1064px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;

    @include mq(md){
      @include props(pt,160,750);
      @include props(mb,200,750);
      @include props(pl,40,750);
      @include props(pr,40,750);
    }

    .faqList{
      li{
        &:not(:last-child){
          @include props(mb,60);

          @include mq(md){
            @include props(mb,60,750);
          }
        }
        dl{
          dt{
            @include props(p,30);
            background: $keyColor;
            @include props(btlr,20);
            @include props(btrr,20);
            display: flex;
            align-items: baseline;

            p{
              flex: 1;
              @include fz_vw(18);
              color: $basicColor;
            }

            &:before{
              content: "Q";
              @include props(mr,30);
              @include fz_vw(18);
            }

            &:after{
              content: "";
              background: url(/images/common/accordionBtn.svg) center right no-repeat;
              background-size: contain;
              width: 1.11em;
              height: 0.55em;
              margin-left: 2em;
              transition: transform .3s;
            }

            &.isOpen{
              &.accordionBtn::after{
                transform: rotate(180deg);
              }
            }

            @include mq(md){
              @include props(pt,30,750);
              @include props(pb,30,750);
              @include props(pl,40,750);
              @include props(pr,40,750);
              @include props(btlr,20,750);
              @include props(btrr,20,750);

              p{
                @include fz_vw(28);
              }

              &:before{
                @include fz_vw(28);
                @include props(mr,30,750);
              }
            }
          }

          dd{
            @include props(p,30);
            @include props(bblr,20);
            @include props(bbrr,20);
            display: flex;
            align-items: baseline;
            border: 1px solid $keyColor;

            p{
              @include fz_vw(18);
              flex: 1;
              
              img{
                @include props(mt,30);
                margin-left: auto;
                margin-right: auto;
                @include props(br,20);
              }

              a{
                color: $basicColor;
                text-decoration: underline;
                transition: opacity .3s;

                &:hover{
                  opacity: .5;
                }
              }
            }

            &:before{
              content: "A";
              @include props(mr,30);
              @include fz_vw(18);
            }

            @include mq(md){
              @include props(pt,30,750);
              @include props(pb,30,750);
              @include props(pl,40,750);
              @include props(pr,40,750);
              @include props(bblr,20,750);
              @include props(bbrr,20,750);

              p{
                @include fz_vw(28);

                img{
                  @include props(mt,40,750);
                  @include props(br,20,750);
                }
              }

              &:before{
                @include fz_vw(28);
                @include props(mr,30,750);
              }
            }
          }
        }
      }
    }
  }
}
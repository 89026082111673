@charset "utf-8";

/* =====================================================
フッター
===================================================== */

#footer{
	background: #4B4B4B;
	@include props(btrr,200);
	@include props(pt,120);
	@include props(pb,60);

	@include mq(md){
		@include props(btrr,200,750);
		@include props(pt,240,750);
		@include props(pb,120,750);
	}

	.footerInner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		flex-wrap: wrap;

		@include mq(md){
			flex-direction: column-reverse;
		}
		
		.companyInfo{
			width: 14.34%;
			img{
				width: 100%;
			}

			@include mq(md){
				width: 100%;
				@include props(mt,120,750);
				img{
					margin: 0 auto;
					width: 29.25%;
				}
			}
		}

		.informationBox{
			width: 60%;

			@include mq(tab){
				width: 75%;
			}

			@include mq(md){
				width: 100%;
			}

			.pageNav{
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				flex-wrap: wrap;

				a{
					color: $basicWhiteColor;
					@include fz_vw(14);

					@include mq(md){
						@include fz_vw(28);
					}
				}

				.pageNavList{
					@include mq(md){
						&:nth-child(3){
							@include props(mt,80,750);
							@include props(mb,80,750);
						}
					}
					.pageNavDateList{
						dt{
							@include props(mb,25);
							@include mq(md){
								@include props(mb,40,750);
							}
						}

						dd{
							.innerLinkArea{
								.innerLinkList{
									a{
										padding-left: 1.8em;
										position: relative;
										&:before{
											content: "";
											position: absolute;
											width: 1.2em;
											height: 1px;
											background: $basicWhiteColor;
											left: 0;
											top: 0;
											bottom: 0;
											margin: auto 0;
										}
									}
								}
							}
						}
					}
				}
			}

			.informationAnnounce{
				color: $basicWhiteColor;
				@include fz_vw(14);
				@include props(mt,40);

				@include mq(md){
					@include fz_vw(24);
					@include props(mt,60,750);
				}
				a{
					color: $basicWhiteColor;
					@include fz_vw(14);
					text-decoration: underline;
					@include mq(md){
						@include fz_vw(24);
					}
				}
			}
		}
	}
}
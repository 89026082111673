@charset "utf-8";
/* ==============================================
ポリシーページ
============================================== */

#shopTop{
	.shopContent{
		@include props(pt,160);
		@include props(pb,200);
		width: 100%;
		margin: 0 auto;
	
		@include mq(md){
		  @include props(pt,120,750);
		  @include props(pb,200,750);
		  @include props(pl,40,750);
		  @include props(pr,40,750);
		}
		.shopContentDesc{
			@include fz_vw(16);
			@include props(mb,120);
			@include mq(md){
				@include fz_vw(28);
				@include props(mb,80,750);
			}
			a{
				color: $basicColor;
				text-decoration: underline;
			}
		}

		.shopListArea{
			.shopList{
				&:not(:last-child){
					@include props(mb,120);

					@include mq(md){
						@include props(mb,120,750);
					}
				}
				background: #EDEDED;
				@include props(br,60);
				@include props(pt,60);
				@include props(pb,60);
				text-align: center;

				@include mq(md){
					@include props(br,40,750);
					@include props(pt,80,750);
					@include props(pb,80,750);
				}

				.shopListTit{
					display: inline-block;
					position: relative;
					@include fz_vw(24);

					&:before{
						content: "";
						position:absolute;
						top: 0;
						bottom: 0;
						height: 1px;
						width: 2.91em;
						left: -2.5em;
						margin: auto 0;
						background: $keyColor;
						transform: translate(-100%,0);
						@include mq(md){
							width: 1.66em;
							left: -1.11em;
						}
					}

					&:after{
						content: "";
						position:absolute;
						top: 0;
						bottom: 0;
						height: 1px;
						width: 2.91em;
						right: -2.5em;
						margin: auto 0;
						background: $keyColor;
						transform: translate(100%,0);
						@include mq(md){
							width: 1.66em;
							right: -1.11em;
						}
					}

					@include mq(md){
						@include fz_vw(36);
					}
				}

				.shopInfoArea{
					text-align: left;
					@include props(mt,40);
					margin-left: auto;
					margin-right: auto;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					flex-wrap: wrap;
					width:74.96%;

					@include mq(tab){
						width: calc(100% - 40px);
					}

					@include mq(md){
						@include props(mt,60,750);
						width: 100%;
						@include props(pl,40,750);
						@include props(pr,40,750);
					}

					dl{
						width: 42.38%;
						display: flex;
						align-items: flex-start;
						justify-content: space-between;
						flex-wrap: wrap;

						@include mq(md){
							width: 100%;
						}
						
						dt{
							width: 33%;
							@include fz_vw(16);
							margin-bottom: 1em;

							@include mq(md){
								@include fz_vw(28);
							}
						}
						dd{
							width: 67%;
							@include fz_vw(16);
							margin-bottom: 1em;

							@include mq(md){
								@include fz_vw(28);
							}

							&.snsArea{
								display: flex;
								align-items: flex-start;
								flex-wrap: wrap;
								text-align: center;

								.snsLink{
									&:not(:nth-of-type(1)){
										@include props(ml,15);

										@include mq(md){
											@include props(ml,15,750);
										}
									}

									img{
										@include props(w,45);
										margin: 0 auto;

										@include mq(md){
											@include props(w,75,750);
										}
									}
								}
							}

							span{
								@include fz_vw(14);

								@include mq(md){
									@include fz_vw(22);
								}
							}

							a:not(.snsLink){
								@include fz_vw(14);
								color: $basicColor;
								text-decoration: underline;

								@include mq(md){
									@include fz_vw(22);
								}
							}
						}
					}

					.infoMap{
						width: 53.71%;

						@include mq(md){
							width: 100%;
						}

						.infoMapInner{
							position: relative;
							padding-top: 56.25%;

							iframe{
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								@include props(br,20);

								@include mq(md){
									@include props(br,20,750);
								}
							}
						}
					}

					.cvBtnWrap{
						width: 100%;
						@include props(mt,60);

						@include mq(md){
							@include props(mt,60,750);
						}
						
						.cvBtn{
							width: 36.6%;
							margin: 0 auto;
							text-align: center;
							border-radius: 100px;
							background: $keyColor;
							transition: all .3s;
							border: 1px solid $keyColor;

							@include mq(md){
								width: 100%;
							}

							a{
								color: $basicWhiteColor;
								@include fz_vw(16);
								@include props(p,15);
								display: block;

								@include mq(md){
									@include fz_vw(28);
									@include props(p,20,750);
								}
							}

							&:hover{
								cursor: pointer;
								background: $basicWhiteColor;
								color: $keyColor;
				
								a{
								  color: $keyColor;
								}
							}
						}
					}
				}
			}
		}
	}
}
@charset "utf-8";
/* ボタン装飾------------------------------ */
button {
  outline: none;
}

.linkBoxBtn{
  @include props(w,280);
  margin: 0 auto;
  text-align: center;
  transition: opacity .3s;
  background: $bgColor;
  &:hover{
    opacity: .5;
  }

  a{
    @include fz_vw(14);
    padding: 1em;
    display: inline-block;
    width: 100%;
    height: 100%;
    border:1px solid $basicColor;
    @include props(br,10);

    &.external{
      &:after{
        content: "";
        background: url(/images/common/btn_blank.svg) center center no-repeat;
        background-size: contain;
        width: 1em;
        height: 1em;
        display: inline-block;
        margin-left: .5em;
      }
    }
  }

  @include mq(md){
    @include props(w,380,750);

    a{
      @include fz_vw(24);
      @include props(br,10,750);
    }
  }
}

.modalLinkBoxBtn{
  @include props(w,630);
  margin: 0 auto;
  text-align: center;
  @include props(mt,30);
  background: $bgColor;
  transition: opacity .3s;
  &:hover{
    opacity: .6;
  }

  &:not(:last-of-type){
    @include props(mb,60);
  }

  a{
    @include fz_vw(18);
    padding: 1em;
    display: inline-block;
    width: 100%;
    height: 100%;
    border:1px solid $basicColor;
    @include props(br,20);

    &.external{
      &:after{
        content: "";
        background: url(/images/common/btn_blank.svg) center center no-repeat;
        background-size: contain;
        width: 1em;
        height: 1em;
        display: inline-block;
        margin-left: .5em;
      }
    }
  }

  @include mq(md){
    @include props(w,630,750);
    @include props(mt,20,750);

    &:not(:last-of-type){
      @include props(mb,60,750);
    }
    a{
      @include fz_vw(28);
      @include props(br,20,750);
    }
  }
}


#cvFixedBtn{
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;

  @include mq(md){
    @include props(w,250,750);
    @include props(h,250,750);
    @include props(b,40,750);
    @include props(r,40,750);
  }

  a{
    img{
      @include props(w,250);
      @include props(h,250);

      @include mq(md){
        @include props(w,250,750);
        @include props(h,250,750);
      }
    }
  }

  button{
    background: $basicColor;
    @include props(w,40);
    @include props(h,40);
    // width: 40px;
    // height: 40px;
    border-radius: 50%;
    position: absolute;
    @include props(t,12);
    @include props(r,12);
    // top: 12px;
    // right: 12px;
    &:hover{
      cursor: pointer;
    }

    @include mq(md){
      @include props(w,40,750);
      @include props(h,40,750);
      @include props(t,12,750);
      @include props(r,12,750);
    }

    span{
      @include props(w,23);
      // width: 23px;
      height: 1px;
      background: #EFEFEF;
      position: absolute;
      top: 50%;
      left: 50%;

      &:nth-of-type(1){
        transform: translate(-50%,-50%) rotate(-45deg);
      }
      &:nth-of-type(2){
        transform: translate(-50%,-50%) rotate(45deg);
      }
      
      @include mq(md){
        @include props(w,23,750);
      }
    }
  }
}

.sendBtn{
  @include props(w,300);
  @include props(mt,120);
  margin-left: auto;
  margin-right: auto;

  @include mq(md){
    @include props(mt,80,750);
    width: 100%;
    max-width: 100%;
  }

  a,button{
    display: block;
    width: 100%;
    height: 100%;
    @include props(p,14);
    @include fz_vw(16);
    color: $basicWhiteColor;
    background: $keyColor;
    border: 1px solid $keyColor;
    text-align: center;
    border-radius: 100px;
    transition: all .3s;
    font-family: $base-font;
    font-weight: 400;

    @include mq(md){
      @include props(p,16,750);
      @include fz_vw(28);
    }

    span{
      @include fz_vw(16);
      @include mq(md){
        @include fz_vw(28);
      }
    }

    &:hover{
      cursor: pointer;
      opacity: .6;
      background: $basicWhiteColor;
      color: $keyColor;
    }
  }
}
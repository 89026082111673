@charset "utf-8";

.columnLayout {
	display: flex;
	justify-content: space-between;
	@for $i from 1 through 8 {
		$width: percentage(1 / $i);
		.col#{$i} {
			flex-basis: floor($width);
		}
	}
}

// ローディング画面
#loading {
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	width: auto;
	min-width: 100%;
	height: auto;
	min-height: 100%;
	background: #ffffff;
	opacity: 1;
	z-index: 100000;
}

#mainVisual.lowerPageMainVisual{
	width: 100%;
	position: relative;
	opacity: 0;
	transition: opacity .3s;

	&.mainVisualActive{
		opacity: 1;

		.mainTtl{
			opacity: 1;
		}
	}
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.mainTtl{
		opacity: 0;
		transition: opacity .3s .6s;
		@include fz_vw(70);
		color: $keyColor;
		position: absolute;
		width: 100%;
        max-width: 1560px;
        padding: 0 20px;
        left: 50%;
        transform: translate(-50%,0);
		@include props(b,-30);
		font-weight: 500;
		line-height: 1.4;

        @include mq(md){
          @include props(b,-40,750);
          @include props(pl,40,750);
          @include props(pr,40,750);
          @include fz_vw(70);
        }

		span{
			@include fz_vw(18);
			color: $basicColor;
			display: block;

			@include mq(md){
				@include fz_vw(28);
			}
		}
	}
}

.sectionTtl{
	text-align: center;
	@include props(mb,80);

	@include mq(md){
		text-align: left;
		@include props(mb,80,750);
	}
	span{
		@include fz_vw(20);
		&:before{
			content: "";
			@include props(w,40);
			height: 1px;
			background: $keyColor;
			display: inline-block;
			vertical-align: middle;
			margin-right: 1.5em;
		}
		&:after{
			content: "";
			@include props(w,40);
			height: 1px;
			background: $keyColor;
			display: inline-block;
			vertical-align: middle;
			margin-left: 1.5em;
		}

		@include mq(md){
			@include fz_vw(28);
			&:before{
				@include props(w,40,750);
				margin-right: .5em;
			}

			&:after{
				content: none;
			}
		}
	}

	h3{
		@include fz_vw(40);
		@include mq(md){
			@include fz_vw(48);
		}
	}
}


//モーダルコンテント
.detailModalContent{
	width: 100%;
	height: 100%;
	margin: 0;
	min-height: 100%;
	position: fixed;
	z-index: 1000;
	display: none;
	top: 0;
	left: 50%;
	transform: translate(-50%,0);
	background-color: $bgColor;
	@include props(pt,126);
	overflow-y: auto;

	@include mq(md){
		@include props(pt,0);
	}

	.modalInner{
		position: relative;
		margin: 0 auto;
		width: 100%;
		max-width: 1064px;
		margin: 0 auto;
		padding-left: 20px;
		padding-right: 20px;
		@include props(pt,200);
		text-align: center;
		height: 100vh;
		@include mq(md){
			@include props(pt,190,750);
			@include props(pl,40,750);
			@include props(pr,40,750);
		}

		.close{
			position: absolute;
			@include props(t,15);
			@include props(r,15);
			z-index: 100;
			opacity: 1;
			
			a{
				display: block;
				@include props(w,30);
				@include props(h,30);
				background: url(/images/index/modal_close_btn.svg);
				background-size: contain;
	
				&:hover{
					cursor: pointer;
				}
			}

			@include mq(md){
				@include props(t,40,750);
				@include props(r,40,750);
				a{
					@include props(w,40,750);
					@include props(h,40,750);
				}
			}
		}

		.modalContent{
			@include props(pb,120);
			.modalTtl{
				@include fz_vw(28);
				@include props(mb,80);
				&:not(:nth-of-type(1)){
					@include props(mt,200)
				}
			}

			.modalDesc{
				text-align: center;
				@include fz_vw(18);
				@include props(mb,120);

				.textUnder{
					text-decoration: underline;
					color: $basicColor;
				}
			}

			.linkWrap{
				.modalBtnTxt{
					@include fz_vw(18);
				}
			}

			.imgBox{
				margin: 0 auto;
				@include props(w,630);
			}

			@include mq(md){
				@include props(pb,120,750);
				.modalTtl{
					@include fz_vw(36);
					@include props(mb,60,750);

					&:not(:nth-of-type(1)){
						@include props(mt,160,750)
					}
				}
	
				.modalDesc{
					text-align: left;
					@include fz_vw(32);
					@include props(mb,100,750);
				}
	
				.linkWrap{
					.modalBtnTxt{
						@include fz_vw(32);
					}
				}
	
				.imgBox{
					margin: 0 auto;
					@include props(w,630,750);
				}
			}
		}
	}
}
